import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  primesuccursales: {
    count: 0,
    data: [],
    num_pages: 1,
    page: 1,
    page_size: "5",
  },
};

const slice = createSlice({
  name: "primesuccursale",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRIME TIRAGE
    getPrimeSuccursaleSuccess(state, action) {
      state.isLoading = false;
      state.primesuccursales = action.payload;
    },

    // ADD PRIME TIRAGE
    addPrimeSuccursaleSuccess(state, action) {
      const primesuccursale = action.payload;
      state.primesuccursales.data.push(primesuccursale);
      state.primesuccursales.count += 1;
    },

    // UPDATE PRIME TIRAGES
    updatePrimeSuccursaleSuccess(state, action) {
      const primesuccursale = action.payload;
      if (state.primesuccursales && state.primesuccursales.data) {
        const primesuccursaleIndex = state.primesuccursales?.data?.findIndex(
          (obj) => obj.id === primesuccursale.id
        );
        if (primesuccursaleIndex !== -1) {
          state.isLoading = false;
          state.primesuccursales.data[primesuccursaleIndex] = primesuccursale;
        }
      } else {
        state.primesuccursales = { data: [] };
        state.primesuccursales.data.push(primesuccursale);
      }
    },

    // DELETE PRIME TIRAGE
    deletePrimeSuccursaleSuccess(state, action) {
      const deletedPrimeSuccursaleId = action.payload;
      state.primesuccursales.data = state.primesuccursales.data.filter(
        (primesuccursale) => primesuccursale.id !== deletedPrimeSuccursaleId
      );
      state.isLoading = false;
    },

    // DELETE PRIME TIRAGES
    deletePrimeSuccursalesSuccess(state, action) {
      const deletedPrimeSuccursaleIds = action.payload;
      state.primesuccursales.data = state.primesuccursales.data.filter(
        (primesuccursale) => !deletedPrimeSuccursaleIds.includes(primesuccursale.id)
      );
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  addPrimeSuccursaleSuccess,
  updatePrimeSuccursaleSuccess,
  deletePrimeSuccursaleSuccess,
  deletePrimeSuccursalesSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getPrimeSuccursales(page = 1, rowsPerPage = 5) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/v1/dashboard/prime-succursale?page=${page}&page_size=${rowsPerPage}`
      );
      dispatch(slice.actions.getPrimeSuccursaleSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function getPrimeSuccursale(keyId) {
  const response = await axios.get(
    `/api/v1/dashboard/prime-succursale-id?keyId=${keyId}`
  );
  return response.data;
}

export async function addPrimeSuccursale(data) {
  const response = await axios.post(`/api/v1/dashboard/prime-succursale`, data);
  return response.data;
}

export async function updatePrimeSuccursale(data) {
  const response = await axios.put(`/api/v1/dashboard/prime-succursale`, data);
  return response.data;
}

export async function deletePrimeSuccursale(keyId) {
  const response = await axios.delete(`/api/v1/dashboard/prime-succursale/${keyId}/`);
  return response.data;
}

export async function deletePrimeSuccursales(keyIds) {
  const response = await axios.delete(`/api/v1/dashboard/prime-succursale-id`, {
    data: { keyIds },
  });
  return response.data;
}
