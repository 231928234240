import { createSlice } from "@reduxjs/toolkit";
import { fDateOnly } from "../../utils/formatTime";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  payments: null,
};

const slice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PAYMENTS
    getPaymentsSuccess(state, action) {
      state.isLoading = false;
      state.payments = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getPayments(filterStartDate = null, filterEndDate = null) {
  const date =
    filterStartDate && filterEndDate
      ? `?start_date=${filterStartDate}&end_date=${filterEndDate}`
      : "";
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/dashboard/payments${date}`);
      dispatch(slice.actions.getPaymentsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
