// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import SvgIconStyle from "../../../components/SvgIconStyle";
import Label from "../../../components/Label";
import { useTranslation } from "react-i18next";
// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle
    src={`/static/icons/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  dashboard: getIcon("ic_dashboard"),
  supervisor: getIcon("ic_supervisor"),
  vendor: getIcon("ic_vendor"),
  succursale: getIcon("ic_succursale"),
  customer: getIcon("ic_customer"),
  configuration: getIcon("ic_configuration"),
  security_and_limit: getIcon("ic_security_and_limit"),
  ticket: getIcon("ic_ticket"),
  report: getIcon("ic_report"),
  transaction: getIcon("ic_transaction"),
  user: getIcon("ic_user"),
};

const NavConfig = () => {
  const { t } = useTranslation();
  const navConfig = [
    // OVERVIEW
    {
      subheader: t("sideBar.OVERVIEW"),
      items: [
        {
          title: t("params.dashboard"),
          path: PATH_DASHBOARD.general.app,
          icon: ICONS.dashboard,
        },
      ],
    },
    // MANAGEMENT
    // ----------------------------------------------------------------------
    {
      subheader: t("sideBar.MANAGEMENT"),
      items: [
        {
          title: t("sideBar.supervisor"),
          path: PATH_DASHBOARD.supervisor.root,
          icon: ICONS.supervisor,
          children: [
            {
              title: t("sideBar.add_supervisor"),
              path: PATH_DASHBOARD.supervisor.add,
            },
            {
              title: t("sideBar.list_supervisor"),
              path: PATH_DASHBOARD.supervisor.list,
            },
          ],
        },
        {
          title: t("sideBar.succursale"),
          path: PATH_DASHBOARD.succursale.root,
          icon: ICONS.succursale,
          children: [
            {
              title: t("sideBar.add_succursale"),
              path: PATH_DASHBOARD.succursale.add,
            },
            {
              title: t("sideBar.list_succursale"),
              path: PATH_DASHBOARD.succursale.list,
            },
          ],
        },
        {
          title: t("sideBar.vendor"),
          path: PATH_DASHBOARD.vendor.root,
          icon: ICONS.vendor,
          children: [
            { title: t("sideBar.add_vendor"), path: PATH_DASHBOARD.vendor.add },
            {
              title: t("sideBar.list_vendor"),
              path: PATH_DASHBOARD.vendor.list,
            },
          ],
        },
      ],
    },
    // SECURITY
    {
      subheader: t("sideBar.SECURITY"),
      items: [
        {
          title: t("sideBar.security_and_limit"),
          path: PATH_DASHBOARD.security_and_limit.root,
          icon: ICONS.security_and_limit,
          children: [
            {
              title: t("sideBar.statistique"),
              path: PATH_DASHBOARD.security_and_limit.statistique,
            },
            {
              title: t("sideBar.bouleblock"),
              path: PATH_DASHBOARD.security_and_limit.bouleblock,
            },
            {
              title: t("sideBar.prime_tirage"),
              path: PATH_DASHBOARD.security_and_limit.prime_tirage,
            },
            {
              title: t("sideBar.prime_user"),
              path: PATH_DASHBOARD.security_and_limit.prime_user,
            },
            {
              title: t("sideBar.prime_succursale"),
              path: PATH_DASHBOARD.security_and_limit.prime_succursale,
            },
            {
              title: t("sideBar.limit_game"),
              path: PATH_DASHBOARD.security_and_limit.limit_game,
            },
            {
              title: t("sideBar.limit_game_tirage"),
              path: PATH_DASHBOARD.security_and_limit.limit_game_tirage,
            },
            {
              title: t("sideBar.limit_ticket"),
              path: PATH_DASHBOARD.security_and_limit.limit_ticket,
            },
            {
              title: t("sideBar.limit_vente"),
              path: PATH_DASHBOARD.security_and_limit.limit_vente,
            },
            {
              title: t("sideBar.limit_quantityboule"),
              path: PATH_DASHBOARD.security_and_limit.limit_quantityboule,
            },
            {
              title: t("sideBar.limit_boule"),
              path: PATH_DASHBOARD.security_and_limit.limit_boule,
            },
            {
              title: t("sideBar.limit_boule_tirage"),
              path: PATH_DASHBOARD.security_and_limit.limit_boule_tirage,
            },
            {
              title: t("sideBar.lotgagnant"),
              path: PATH_DASHBOARD.security_and_limit.lotgagnant,
            },
          ],
        },
      ],
    },

    // TRANSACTIONS
    {
      subheader: t("sideBar.TRANSACTIONS"),
      items: [
        // TICKETS
        {
          title: t("sideBar.ticket"),
          path: PATH_DASHBOARD.ticket.root,
          icon: ICONS.ticket,
          children: [
            {
              title: t("sideBar.ticket_sale"),
              path: PATH_DASHBOARD.ticket.sale,
            },
            { title: t("sideBar.ticket_win"), path: PATH_DASHBOARD.ticket.win },
          ],
        },
        // REPORTS
        {
          title: t("sideBar.report"),
          path: PATH_DASHBOARD.report.root,
          icon: ICONS.report,
          children: [
            {
              title: t("sideBar.report_sale"),
              path: PATH_DASHBOARD.report.sale,
            },
            {
              title: t("sideBar.report_user"),
              path: PATH_DASHBOARD.report.user,
            },
          ],
        },
        // TRANSACTIONS
        // {
        //   title: t("sideBar.transaction"),
        //   path: PATH_DASHBOARD.transaction.root,
        //   icon: ICONS.transaction,
        //   children: [
        //     {
        //       title: t("sideBar.add_transaction"),
        //       path: PATH_DASHBOARD.transaction.add,
        //     },
        //     {
        //       title: t("sideBar.list_transaction"),
        //       path: PATH_DASHBOARD.transaction.list,
        //     },
        //   ],
        // },
      ],
    },

    // PARAMETTRE
    // ----------------------------------------------------------------------
    {
      subheader: t("sideBar.PARAMETERS"),
      items: [
        // USER
        {
          title: t("sideBar.user"),
          path: PATH_DASHBOARD.user.root,
          icon: ICONS.user,
          children: [
            { title: t("sideBar.edit"), path: PATH_DASHBOARD.user.account },
          ],
        },
      ],
    },
  ];
  return navConfig;
};

export default NavConfig;
